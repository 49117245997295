import React, { useState, useEffect } from 'react';
import { FaCartArrowDown } from "react-icons/fa";
import './product_card.css';
import { API_ENDPOINTS } from '../../connectapi';

const ProductCard = ({ images, name, price, colors, loading }) => {
  const [hover, setHover] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false); // New state for individual card loading

  useEffect(() => {
    let interval;
    if (hover && isLoaded) { // Only start interval when image is loaded
      interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [hover, images, isLoaded]);

  const handleImageLoad = () => {
    setIsLoaded(true); // Set loading to false once the image is loaded
  };


  const currentImageUrl = typeof images[currentImageIndex] === 'string'
    ? `${API_ENDPOINTS.getAPI}${images[currentImageIndex]}`
    : `${API_ENDPOINTS.getAPI}${images[currentImageIndex]?.url}`;

  if (images.length > 0 && currentImageIndex >= 0 && currentImageIndex < images.length) {

    return (
      <div
        className={`product-card-container ${loading && !isLoaded ? 'loading' : ''}`} // Apply loading class if not loaded
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className="product-card">
          <div className="product-card-image">
            {!isLoaded && <div className="image-placeholder"></div>} {/* Placeholder for loading */}
            <img
              draggable='false'
              src={currentImageUrl}
              alt={name}
              onLoad={handleImageLoad}
              onError={(e) => (e.target.src = 'https://placehold.co/600x400')}
            />
            {hover && isLoaded && <button className="detail-button">+ <FaCartArrowDown /></button>}
          </div>
          <div className="product-card-content">
            <div className="color-options">
              {colors.map((color, index) => (
                <button
                  key={index}
                  className="color-button"
                  style={{ backgroundColor: color }}
                ></button>
              ))}
            </div>
          </div>
        </div>
        <div className="product-card-info">
          <p className="product-name">{name}</p>
          <p className="product-price"><span>{price} บาท</span></p>
        </div>

      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default ProductCard;