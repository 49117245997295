import React, { useState, useEffect, useRef } from 'react';
import '../css/productdetail.css';
import '../css/body.css';
import { Link } from 'react-router-dom';
import ZoomableImage from './Productdetail_zoom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS } from '../connectapi';
import Footer from '../responsivebar/Footer';
//import ProductCard from './cards/product_card';
import Lazadajsx from '../icons/Lazadajsx';
import Tiktokjsx from '../icons/Tiktokicon';
import SuggestionShow from '../showcontentes/SuggestionShow';

const ProductDetail = () => {
  const { productId } = useParams();
  const [productdata, setProductdata] = useState(null);
  const [selectedColor, setSelectedColor] = useState('see-all');
  const [selectedSize, setSelectedSize] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isScrolled, setIsScrolled] = useState(true);
  const thumbnailContainerRef = useRef(null);
  const infoBoxRef = useRef(null);
  const videoRef = useRef(null);

  const [isZoomOpen, setIsZoomOpen] = useState(false);
  const [zoomImage, setZoomImage] = useState('');

  const [isColorDropdownOpen, setIsColorDropdownOpen] = useState(false);
  const [isSizeDropdownOpen, setIsSizeDropdownOpen] = useState(false);

  const colorDropdownRef = useRef(null);

  const [suggestedProducts, setSuggestedProducts] = useState([]);


  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const [sizeData, setSizeData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchSizeData = async () => {
      try {
        // เรียกใช้ฟังก์ชัน getDetailSize กับ productId เพื่อรับ URL
        const url = API_ENDPOINTS.getDetailSize(productId);

        const response = await axios.get(url);

        if (response.data.status === 'success') {
          setSizeData(response.data.data);
        } else {
          console.error(response.data.message);
          setErrorMessage(response.data.message);
        }
      } catch (error) {
        console.error('Error fetching size data:', error);
        setErrorMessage('ไม่สามารถโหลดข้อมูลขนาดได้ในขณะนี้. โปรดลองใหม่อีกครั้งภายหลัง.');
      } finally {
        setIsLoading(false);
      }
    };

    if (productId) {
      fetchSizeData();
    }
  }, [productId]);

  useEffect(() => {
    const fetchSuggestedProducts = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.getlistsuggestions(productId));
        const data = response.data;

        if (Array.isArray(data)) {
          const transformedProducts = data.map((product) => ({
            pro_id: product.pro_ID,
            image: product.proimg_url
              ? `${API_ENDPOINTS.baseurl}/${product.proimg_url}`
              : 'https://placehold.co/600x400',
            colors: product.colors || [],
          }));

          setSuggestedProducts(transformedProducts);
        } else {
          console.error('Error fetching suggested products:', data.error);
        }
      } catch (error) {
        console.error('Error fetching suggested products:', error);
      }
    };

    fetchSuggestedProducts();
  }, [productId]);


  // จัดระเบียบข้อมูลขนาดให้อยู่ในรูปแบบที่เหมาะสม
  const organizedSizes = sizeData.reduce((acc, curr) => {
    const { size_label, attribute_name, value } = curr;
    if (!acc[size_label]) {
      acc[size_label] = {};
    }
    acc[size_label][attribute_name] = value;
    return acc;
  }, {});

  // ดึงชื่อขนาดจากข้อมูลที่จัดระเบียบแล้ว
  const sizeLabels = Object.keys(organizedSizes); // ลบ .sort() ออก
  const toggleContent1 = () => {
    setIsChecked1(!isChecked1);
  };

  const toggleContent2 = () => {
    setIsChecked2(!isChecked2);
  };

  useEffect(() => {
    if (isColorDropdownOpen) {
      handleDropdownPosition();
      document.addEventListener('click', handleClickOutside, true);
    }
    return () => document.removeEventListener('click', handleClickOutside, true);
  }, [isColorDropdownOpen]);

  const handleClickOutside = (event) => {
    if (colorDropdownRef.current && !colorDropdownRef.current.contains(event.target)) {
      setIsColorDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isSizeDropdownOpen) {
      handleDropdownPosition();
    }
  }, [isSizeDropdownOpen]);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.getdetailProducts(productId));
        const product = response.data;
        setProductdata(product);
        setSelectedSize(product.variations[0]?.size || '');
        setSelectedColor('see-all');
        setCurrentSlide(0);

      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    fetchProductData();
  }, [productId]);

  useEffect(() => {
    const handleScroll = () => {
      if (infoBoxRef.current) {
        const bounding = thumbnailContainerRef.current.getBoundingClientRect();
        setIsScrolled(bounding.bottom > window.innerHeight);
      }
      if (videoRef.current) {
        const videoBounding = videoRef.current.getBoundingClientRect();
        if (videoBounding.top >= 0 && videoBounding.bottom <= window.innerHeight) {
          if (videoRef.current.paused) {
            videoRef.current.play().catch(error => {
              console.error('Error attempting to play video:', error);
            });
          }
        } else {
          if (!videoRef.current.paused) {
            videoRef.current.pause();
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleDropdownPosition = () => {
    const dropdown = colorDropdownRef.current;
    if (!dropdown) return;

    const bounding = dropdown.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const dropdownHeight = dropdown.scrollHeight;

    if (bounding.bottom + dropdownHeight > windowHeight && colorOptions.length > 1) {
      dropdown.classList.add('open-up');
    } else {
      dropdown.classList.remove('open-up');
    }
  };

  const getUniqueValues = (variations, key) => {
    return [...new Set(variations.map(variation => variation[key]))];
  };

  const handleColorChange = (color) => {
    if (color === 'see-all') {
      setSelectedColor('see-all'); // Show all images
    } else {
      setSelectedColor(color); // Filter by selected color
    }
    setCurrentSlide(0); // Reset slide to the first one
    setIsColorDropdownOpen(false); // Close the dropdown
  };

  const handleSizeChange = (size) => {
    setSelectedSize(size);
    setIsSizeDropdownOpen(false); // Close the dropdown
  };

  const filteredImages = productdata?.variations
    .filter(variation => variation.color === selectedColor || selectedColor === 'see-all')
    .flatMap(variation => variation.images.map(image => `${API_ENDPOINTS.getAPI}${image}`)) || [];


  const videoUrl = productdata?.pro_videourl ? `${API_ENDPOINTS.getAPI}${productdata.pro_videourl}` : null;

  // Determine the number of slides (3 images per slide)
  const imageSlides = Math.ceil(filteredImages.length / 3);
  // Add one slide if there is a video
  const totalSlides = imageSlides + (videoUrl ? 1 : 0);

  const handleNextImage = () => {
    if (isTransitioning || totalSlides === 0) return;

    const isMobile = window.innerWidth <= 768;

    setIsTransitioning(true);
    setCurrentSlide((prev) => {
      const nextSlide = prev + 1;

      if (isMobile) {
        // Mobile: Slide one image at a time, including video
        if (videoUrl) {
          return nextSlide >= filteredImages.length + 1 ? 0 : nextSlide; // Including video as a slide
        } else {
          return nextSlide >= filteredImages.length ? 0 : nextSlide; // Loop after all images if no video
        }
      } else {
        if (videoUrl) {
          return nextSlide >= totalSlides ? 0 : nextSlide; // Desktop: Loop after all slides including video
        } else {
          return nextSlide >= imageSlides ? 0 : nextSlide; // Loop after all image slides if no video
        }
      }
    });
  };

  const handlePreviousImage = () => {
    if (isTransitioning || totalSlides === 0) return;

    const isMobile = window.innerWidth <= 768;

    setIsTransitioning(true);
    setCurrentSlide((prev) => {
      const prevSlide = prev - 1;

      if (isMobile) {
        // Mobile: Slide one image at a time, including video
        if (videoUrl) {
          return prevSlide < 0 ? filteredImages.length : prevSlide; // Including video as a slide
        } else {
          return prevSlide < 0 ? filteredImages.length - 1 : prevSlide; // Loop after all images if no video
        }
      } else {
        if (videoUrl) {
          return prevSlide < 0 ? totalSlides - 1 : prevSlide; // Desktop: Loop after all slides including video
        } else {
          return prevSlide < 0 ? imageSlides - 1 : prevSlide; // Loop after all image slides if no video
        }
      }
    });
  };

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
    if (videoUrl && currentSlide === totalSlides - 1) {
      videoRef.current.play();
    }
  };

  useEffect(() => {
    if (!thumbnailContainerRef.current) return;

    const isMobile = window.innerWidth <= 768; // Detect mobile mode
    let translateX = 0;

    if (isMobile) {
      // Mobile: Slide one image at a time
      translateX = -currentSlide * 100; // Each image takes full width (100%)
    } else {
      // Desktop: Slide three images at a time
      const slideWidth = 100 / 3;
      translateX = -currentSlide * slideWidth * 3; // Translate by 3 images at a time

      // Handle case for incomplete last slide (less than 3 images)
      if (currentSlide === imageSlides - 1 && filteredImages.length % 3 !== 0) {
        const remainingImages = filteredImages.length % 3;
        const adjustWidth = (3 - remainingImages) * slideWidth;
        translateX += adjustWidth;
      }

      // Ensure no extra slide if no video exists and there are exactly 3 images
      if (!videoUrl && currentSlide >= imageSlides) {
        translateX = 0; // Reset to the first image
      }

      // Adjust translation for the video slide only if the video exists
      if (videoUrl && currentSlide === totalSlides - 1) {
        translateX = -(filteredImages.length * slideWidth);
      }
    }

    thumbnailContainerRef.current.style.transition = 'transform 0.5s ease-in-out';
    thumbnailContainerRef.current.style.transform = `translateX(${translateX}%)`;
  }, [currentSlide, totalSlides, imageSlides, filteredImages.length, videoUrl]);



  const handleImageClick = (image) => {
    setZoomImage(image);
    setIsZoomOpen(true);
  };

  const closeZoom = () => {
    setIsZoomOpen(false);
  };

  if (!productdata) {
    return <div>Loading...</div>;
  }

  const colorOptions = getUniqueValues(productdata?.variations || [], 'color');
  const sizeOptions = getUniqueValues(productdata?.variations || [], 'size');

  const getColorHexCode = (color) => {
    const colorData = productdata.variations.find(variation => variation.color === color);
    return colorData ? colorData.hexcode : '#000'; // Default to black if not found
  };
  const columns = [
    { key: 'Chest', label: 'รอบอก' },
    { key: 'Waist', label: 'รอบเอว' },
    { key: 'Length', label: 'ความยาวเสื้อ' },
    { key: 'Height', label: 'ความสูง' },
    { key: 'Width', label: 'ความกว้าง' },
    { key: 'Depth', label: 'ความลึก' },
    { key: 'Circumference', label: 'เส้นรอบวง' },
  ];

  const activeColumns = columns.filter(column =>
    sizeLabels.some(size => organizedSizes[size][column.key])
  );

  // Filter out sizes that don't have any of the required data
  const filteredSizeLabels = sizeLabels.filter(size =>
    activeColumns.some(column => organizedSizes[size][column.key])
  );
  return (
    <div className='product-detail-container'>
      <div className="product-detail">
        <div className="product-images">
          <div className="slideshow-container">
            <button className="scroll-button left" onClick={handlePreviousImage}>
              &lt;
            </button>
            <div className="slideshow">
              <div
                className="images-wrapper"
                ref={thumbnailContainerRef}
                onTransitionEnd={handleTransitionEnd}
                style={{ width: `${totalSlides * 100}%`, display: 'flex' }}
              >
                {filteredImages.map((media, index) => (
                  <img
                    key={index}
                    src={`${media}`}
                    alt={`Product ${index + 1}`}
                    className="product-slide"
                    draggable="false"
                    style={{ flex: '0 0 calc(100% / 3)' }}
                    onClick={() => handleImageClick(media)} // Open zoom on click
                  />
                ))}
                {videoUrl && (
                  <div
                    key="video"
                    className="video-slide"
                    style={{ flex: '0 0 100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <video
                      ref={videoRef}
                      src={videoUrl}
                      muted
                      loop
                      autoPlay
                      style={{ paddingTop: '10%', width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  </div>
                )}
              </div>
            </div>
            <button className="scroll-button right" onClick={handleNextImage}>
              &gt;
            </button>
          </div>

          {/* Info Box */}
          <div
            className={`product-info ${isScrolled ? 'scrolled' : ''}`}
            ref={infoBoxRef}
          >
            <div className="info-left">
              <center>
                <h2>{productdata.name}</h2>
                <p className="price">{productdata.variations[0]?.price} บาท</p>
                <Link to={productdata.variations.find(variation => variation.size === selectedSize && (variation.color === selectedColor || selectedColor === 'see-all'))?.provariation_urllazada}>
                  <button target="_blank" rel="noopener noreferrer" className="platform-button lazada">
                    <Lazadajsx className="Lazadajsx-icon" /> <span>สั่งซื้อที่ Lazada</span>
                  </button>
                </Link>
                <Link to={productdata.variations.find(variation => variation.size === selectedSize && (variation.color === selectedColor || selectedColor === 'see-all'))?.provariation_urltiktok}>
                  <button target="_blank" rel="noopener noreferrer" className="platform-button lazada">
                    <Tiktokjsx className="Lazadajsx-icon" /> <span>สั่งซื้อที่ Tiktok</span>
                  </button>
                </Link>
                <div className="size-selection">
                  {sizeOptions.map((size, index) => (
                    <div
                      key={index}
                      className={`size-item ${selectedSize === size ? 'selected' : ''}`}
                      onClick={() => handleSizeChange(size)}
                    >
                      {size}
                    </div>
                  ))}
                </div>
                <div className="custom-dropdown" ref={colorDropdownRef}>
                  <div
                    className="dropdown-header"
                    onClick={() => {
                      setIsColorDropdownOpen(!isColorDropdownOpen);
                      handleDropdownPosition();
                    }}
                  >
                    {selectedColor === 'see-all' ? 'สีทั้งหมด' : selectedColor}
                  </div>
                  {isColorDropdownOpen && (
                    <div className={`dropdown-list ${isColorDropdownOpen ? 'open-up' : ''}`}>
                      <div className="dropdown-item" onClick={() => handleColorChange('see-all')}>
                        สีทั้งหมด
                      </div>
                      <div className="color-selection">
                        {colorOptions.map((color, index) => {
                          const hexCode = getColorHexCode(color);
                          return (
                            <div
                              key={index}
                              className={`color-item ${selectedColor === color ? 'selected' : ''}`}
                              style={{ backgroundColor: hexCode }}
                              onClick={() => handleColorChange(color)}
                            ></div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </center>
            </div>
            <div className="toggle-content info-right">
              <p onClick={toggleContent1} className='toggle-header'>
                รายละเอียด
                <input type="checkbox" className="plus-minus" checked={isChecked1} readOnly />
              </p>
              <div className={`toggleContainer ${isChecked1 ? 'show' : ''}`}>
                <p className="description" dangerouslySetInnerHTML={{ __html: productdata.detail }} />
              </div>

              <p onClick={toggleContent2} className='toggle-header'>
                ขนาด
                <input type="checkbox" className="plus-minus" checked={isChecked2} readOnly />
              </p>
              <div className={`toggleContainer ${isChecked2 ? 'show' : ''}`}>
                {isLoading ? (
                  <p>กำลังโหลดข้อมูลขนาด...</p>
                ) : errorMessage ? (
                  <p>{errorMessage}</p>
                ) : filteredSizeLabels.length > 0 ? (
                  <table className="size-table" aria-label="ตารางขนาดสินค้า">
                  <thead>
                    <tr>
                      <th>ขนาด</th>
                      {activeColumns.map(column => (
                        <th key={column.key}>{column.label}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredSizeLabels.map(size => (
                      <tr key={size}>
                        <td>{size}</td>
                        {activeColumns.map(column => (
                          <td key={column.key}>{organizedSizes[size][column.key] || '-'}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                ) : (
                  <p>ไม่มีข้อมูลขนาดสำหรับสินค้านี้.</p>
                )}
                <p>หมายเหตุ หน่วยวัดเป็นนิ้ว</p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div>
        <SuggestionShow products={suggestedProducts} />
        {/* <div className="product-card-Dcon">
          <h3>แนะนำ</h3>
          <div className="product-D">
            {suggestedProducts.map((product) => (
              <div className='product-card-D' key={product.pro_id}>
                <Link style={{ color: 'inherit', textDecoration: 'inherit' }} draggable='false' to={`/productDetail/${product.pro_id}`} className="suggested-product-card">
                  <ProductCard
                    images={Array.isArray(product.images) ? product.images.map(img => ({ url: img })) : []}
                    name={product.name}
                    price={product.price}
                    colors={Array.isArray(product.variations) ? product.variations.map(variation => variation.color) : []}
                    loading={false}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
        */}
      </div>
      <Footer />
      {isZoomOpen && (
        <ZoomableImage
          image={zoomImage}
          thumbnails={filteredImages}
          onClose={closeZoom}
        />
      )}
    </div>
  );
};

export default ProductDetail;
