import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import {API_ENDPOINTS} from '../connectapi'; // Adjust the import path as necessary
import './SuggestionShow.css';
import ProductCard from '../pages/cards/product_card';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SuggestionShow = ({ products }) => {
  const containerRef = useRef(null);
  const [productData, setProductData] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const fetchProductData = async () => {
      if (!products || !Array.isArray(products) || products.length === 0) {
        console.error('Invalid or empty products array:', products);
        return;
      }

      try {
        const response = await axios.get(API_ENDPOINTS.getProductList);
        const data = response.data;


        const filteredData = data.filter((product) =>
          products.some((id) => id.pro_id === product.pro_ID)
        );

        setProductData(filteredData);
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    fetchProductData();
  }, [products]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false)
  };

  const handleLinkClick = (e) => {
    if (isDragging) {
      e.preventDefault();
    }
  };

  return (
    <div style={{ paddingBottom: '5rem' }}>
      <center><h2>แนะนำ</h2></center>
      <div className="suggestions" ref={containerRef}>
        <Slider {...settings}>
          {productData.map((product) => {

            return (
              <div key={product.pro_ID} className="suggestion">
                <Link
                  draggable="false"
                  to={`/productDetail/${product.pro_ID}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  onClick={handleLinkClick}
                >
                  <ProductCard
                    name={product.pro_name}
                    images={product.images}
                    price={product.provariation_price || 0}
                    colors={[...new Set(product.variations?.map((v) => v.procol_name) || [])]} // Ensure variations have a 'color' field
                  />
                </Link>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default SuggestionShow;